<template>
    <div id="user_list">
        <el-card>
            <AdminTopOper> </AdminTopOper>

            <!-- 表格主体 -->
            <el-table :data="pageData.rows">
                <el-table-column prop="id" label="编号" width="80" />
                <el-table-column label="文件名" prop="filename" min-width="160" show-overflow-tooltip />
                <el-table-column prop="contact" label="上传用户" width="160">
                    <template #default="scope">
                        <div class="orderContactBox">
                            <img class="orderContactPortrain" :src="scope.row.portrain" alt="">
                            {{ scope.row.username }}
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="纸张大小" prop="size" />
                <el-table-column label="单/双面" prop="double" />
                <el-table-column label="打印材料" prop="material" width="150" />
                <el-table-column label="缩放" prop="scale" width="120" />
                <el-table-column label="页数" prop="pageCount" width="80" />
                <el-table-column label="份数" prop="count" width="80" />
                <el-table-column label="价格" prop="totalPrice" />
                <!-- <el-table-column prop="sort" label="排序" width="80" /> -->
                <el-table-column prop="createdAt" label="创建时间" width="220" />
                <AdminTableButton> </AdminTableButton>
            </el-table>

            <AdminPagination> </AdminPagination>
        </el-card>


    </div>
</template>
  
<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'

const pageData = reactive(Api.adminPage('file'))
pageData.where.count = 2
onMounted(pageData.getPage())

</script>
  
  

<style lang="less">
.orderContactBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    .orderContactPortrain {
        width: 30px;
        border-radius: 4px;
        margin-right: 6px;
    }
}
</style>
  